<template>
  <!-- 修改密码 -->
  <div class="changePassword">
    <van-nav-bar
      :title="$t('changePassword.psdTxt1')"
      left-text
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="oldPassword"
          :type="eye1? 'password':'input'"
          name="oldPassword"
          :label="$t('changePassword.psdTxt2')"
          :placeholder="$t('changePassword.psdTxt2')"
          :rules="[{ required: true, message: $t('changePassword.psdTxt3') }]"
        >
          <template #button>
            <van-icon :name="eye1? 'eye':'closed-eye'" @click="eye1=!eye1"/>
          </template>
        </van-field>
        <van-field
          v-model="newPassword1"
          :type="eye2? 'password':'input'"
          name="newPassword1"
          :label="$t('changePassword.psdTxt4')"
          :placeholder="$t('changePassword.psdTxt4')"
          :rules="[{ required: true, message: $t('changePassword.psdTxt5') }]"
         >
          <template #button>
            <van-icon :name="eye2? 'eye':'closed-eye'" @click="eye2=!eye2"/>
          </template>
        </van-field>
        <van-field
          v-model="newPassword2"
          :type="eye3? 'password':'input'"
          name="newPassword2"
          :label="$t('changePassword.psdTxt6')"
          :placeholder="$t('changePassword.psdTxt6')"
          :rules="[{ required: true, message: $t('changePassword.psdTxt7') }]"
        >
          <template #button>
            <van-icon :name="eye3? 'eye':'closed-eye'" @click="eye3=!eye3"/>
          </template>
        </van-field>
        <!-- <p class="pswDesc">
          密码必须是8-16位的英文字母、数字、字符组合（不能 是纯数字）
        </p>-->
        <div style="margin: 16px;">
          <van-button
            round
            block
            type="info"
            :disabled="!canSave"
            native-type="submit"
            >{{ $t('module.submit') }}</van-button
          >
        </div>
        <div class="mes">
          <p
            v-if="first_changepwd_bottom_caption && $route.query.username"
            v-html="first_changepwd_bottom_caption"
          ></p>
          <p
            v-if="hand_changepwd_bottom_caption && !$route.query.username"
            v-html="hand_changepwd_bottom_caption"
          ></p>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import { changeUserPwd } from '@api/wx.js'
export default {
  data() {
    return {
      userInfo: localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {},
      oldPassword: '',
      newPassword1: '',
      newPassword2: '',
      first_changepwd_bottom_caption: window.first_changepwd_bottom_caption
        ? window.first_changepwd_bottom_caption.replace(/\n/g, '<br><br>')
        : '',
      hand_changepwd_bottom_caption: window.hand_changepwd_bottom_caption
        ? window.hand_changepwd_bottom_caption.replace(/\n/g, '<br><br>')
        : '',
        eye1:true,
        eye2:true,
        eye3:true,
    }
  },
  computed: {
    canSave() {
      if (
        this.newPassword1 &&
        this.newPassword2 &&
        this.newPassword1 == this.newPassword2
      ) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    onClickLeft() {
      if (this.$route.query.form == 'login') {
        this.$router.push('/login')
      } else {
        this.$router.push('/personCen')
      }
    },
    onClickRight() {},
    // 提交
    onSubmit(values) {
      const data = {
        username: this.$route.query.username || this.userInfo.username,
        oldpwd: values.oldPassword,
        newpwd1: values.newPassword1,
        newpwd2: values.newPassword2
      }
      changeUserPwd(data).then(res => {
        if (res.iserror === '0') {
          // 修改缓存密码
          localStorage.setItem('v8_login_pwd', values.newPassword1),
            // Toast.success(this.$t('changePassword.psdTxt8'))
            Dialog.alert({
              message: this.$t('changePassword.psdTxt8')
            }).then(() => {
              // on close
              this.onClickLeft()
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.changePassword {
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .van-form {
    .pswDesc {
      padding: 20px 30px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 40px;
      color: #333333;
    }
    .mes {
      p {
        padding: 0 20px;
        font-size: 36px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #0070c0;
      }
    }
  }
}
</style>
